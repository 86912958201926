var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.customer.defaultService[_vm.scope].service_type === 'WS' ? _vm.wsHeaders : _vm.headers,"items":_vm.customer.defaultService[_vm.scope].default_id !== '' ? [_vm.customer.defaultService[_vm.scope]] : [],"item-key":"service_id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Default Service")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.appScope !== 'GLOBAL')?_c('v-chip',{attrs:{"color":"tertiary","outlined":"","label":""}},[_c('svg-icon',{staticClass:"me-2",attrs:{"path":_vm.appScope}}),_c('b',[_vm._v(_vm._s(_vm.appScope))])],1):_vm._e(),_c('v-spacer'),[_c('delete',{attrs:{"id":"Default Service","is-btn":true,"disabled":_vm.resetIsDisabled},on:{"deleted":function($event){return _vm.resetDefaultService()}}}),(_vm.fromGlobal)?_c('v-btn',{staticClass:"me-4",attrs:{"dark":!_vm.sameAsGlobal,"color":"color_green","width":"175","disabled":_vm.sameAsGlobal},on:{"click":function($event){return _vm.copyConfiguration()}}},[_vm._v(" Copy from GLOBAL ")]):_vm._e(),_c('v-btn',{attrs:{"dark":"","color":"color_green","width":"155"},on:{"click":function($event){return _vm.openConfiguration()}}},[_vm._v(" Configuration ")])]],2)]},proxy:true},{key:"item.service_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_details ? item.service_details[0].service_id : '')+" ")]}},{key:"item.service_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_details ? item.service_details[0].service_name : '')+" ")]}},{key:"item.ws_group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_path.replace('map/', ''))+" ")]}},{key:"footer",fn:function(){return [(_vm.customer.defaultService[_vm.scope].service_type === 'WS')?_c('v-data-table',{staticClass:"mt-5",attrs:{"hide-default-footer":"","headers":_vm.wsSubHeaders,"items":_vm.customer.defaultService[_vm.scope].service_details,"item-key":"service_id","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Services Selected")])],1)]},proxy:true},{key:"item.is_meteogram",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.is_meteogram ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline')+" ")])]}}],null,true)}):_vm._e(),(_vm.customer.defaultService[_vm.scope].service_type === 'WS' && _vm.customer.defaultService[_vm.scope].global_layer_id.length > 0)?_c('v-data-table',{staticClass:"mt-5",attrs:{"hide-default-footer":"","headers":_vm.wsGHeaders,"items":_vm.customer.defaultService[_vm.scope].global_layer_id,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Global Layers")])],1)]},proxy:true}],null,false,1335314850)}):_vm._e(),(_vm.customer.defaultService[_vm.scope].service_type === 'WS' && _vm.customer.defaultService[_vm.scope].customer_layer_id.length > 0)?_c('v-data-table',{staticClass:"mt-5",attrs:{"hide-default-footer":"","headers":_vm.wsGHeaders,"items":_vm.customer.defaultService[_vm.scope].customer_layer_id,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Customer Layers")])],1)]},proxy:true}],null,false,3578132173)}):_vm._e(),_c('v-spacer',{staticClass:"pb-15"})]},proxy:true},{key:"no-data",fn:function(){return [_c('span',{attrs:{"color":"primary"}},[_vm._v(" No default service set. Click on Configuration button to choose a service. ")])]},proxy:true}],null,true)})],1),(_vm.isConfig)?_c('default-service-config',{attrs:{"app-scope":_vm.appScope},on:{"close-config":function($event){return _vm.closeConfiguration()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }