<template>
  <div>
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-data-table
        hide-default-footer
        :headers="customer.defaultService[scope].service_type === 'WS' ? wsHeaders : headers"
        :items="customer.defaultService[scope].default_id !== '' ? [customer.defaultService[scope]] : []"
        class="elevation-1"
        item-key="service_id"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-toolbar-title>Default Service</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            />
            <v-chip
              v-if="appScope !== 'GLOBAL'"
              color="tertiary"
              outlined
              label
            >
              <svg-icon
                :path="appScope"
                class="me-2"
              />
              <b>{{ appScope }}</b>
            </v-chip>
            <v-spacer />
            <template>
              <delete
                id="Default Service"
                :is-btn="true"
                :disabled="resetIsDisabled"
                @deleted="resetDefaultService()"
              />
              <v-btn
                v-if="fromGlobal"
                :dark="!sameAsGlobal"
                color="color_green"
                class="me-4"
                width="175"
                :disabled="sameAsGlobal"
                @click="copyConfiguration()"
              >
                Copy from GLOBAL
              </v-btn>
              <v-btn
                dark
                color="color_green"
                width="155"
                @click="openConfiguration()"
              >
                Configuration
              </v-btn>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:[`item.service_id`]="{ item }">
          {{ item.service_details ? item.service_details[0].service_id : '' }}
        </template>
        <template v-slot:[`item.service_name`]="{ item }">
          {{ item.service_details ? item.service_details[0].service_name : '' }}
        </template>
        <template v-slot:[`item.ws_group`]="{ item }">
          {{ item.service_path.replace('map/', '') }}
        </template>
        <template v-slot:footer>
          <v-data-table
            v-if="customer.defaultService[scope].service_type === 'WS'"
            hide-default-footer
            :headers="wsSubHeaders"
            :items="customer.defaultService[scope].service_details"
            item-key="service_id"
            dense
            class="mt-5"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
              >
                <v-toolbar-title>Services Selected</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:[`item.is_meteogram`]="{ item }">
              <v-icon>
                {{ item.is_meteogram ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
              </v-icon>
            </template>
          </v-data-table>
          <v-data-table
            v-if="customer.defaultService[scope].service_type === 'WS' && customer.defaultService[scope].global_layer_id.length > 0"
            hide-default-footer
            :headers="wsGHeaders"
            :items="customer.defaultService[scope].global_layer_id"
            dense
            class="mt-5"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
              >
                <v-toolbar-title>Global Layers</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-data-table
            v-if="customer.defaultService[scope].service_type === 'WS' && customer.defaultService[scope].customer_layer_id.length > 0"
            hide-default-footer
            :headers="wsGHeaders"
            :items="customer.defaultService[scope].customer_layer_id"
            dense
            class="mt-5"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
              >
                <v-toolbar-title>Customer Layers</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-spacer class="pb-15" />
        </template>
        <template v-slot:no-data>
          <span color="primary"> No default service set. Click on Configuration button to choose a service. </span>
        </template>
      </v-data-table>
    </v-card>
    <default-service-config
      v-if="isConfig"
      :app-scope="appScope"
      @close-config="closeConfiguration()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isEqual } from 'lodash';

export default {
  components: {
    DefaultServiceConfig: () => import('@/components/customer/default-service/DefaultServiceConfig'),
    SvgIcon: () => import('@/components/utils/SvgIcon.vue'),
    delete: () => import('@/components/utils/Delete')
  },

  props: {
    appScope: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      overlay: false,
      isConfig: false,
      headers: [
        {
          text: 'Default ID',
          align: 'start',
          sortable: false,
          value: 'default_id'
        },
        { text: 'Service ID', value: 'service_id', sortable: false },
        { text: 'Service Name', value: 'service_name', sortable: false },
        { text: 'Type', value: 'service_type', sortable: false }
      ],
      wsHeaders: [
        {
          text: 'Default ID',
          align: 'start',
          sortable: false,
          value: 'default_id',
          width: '80%'
        },
        { text: 'Type', value: 'service_type', sortable: false },
        { text: 'Group', value: 'ws_group', sortable: false, width: '10%' }
      ],
      wsSubHeaders: [
        {
          text: 'Service ID',
          align: 'start',
          sortable: false,
          value: 'service_id',
          width: '40%'
        },
        { text: 'Service Name', value: 'service_name', sortable: false, width: '25%' },
        { text: 'Layer Name', value: 'layer_name', sortable: false, width: '20%' },
        { text: 'Layer Type', value: 'layer_type', sortable: false, width: '10%' },
        { text: 'Meteogram', value: 'is_meteogram', sortable: false, width: '5%', align: 'center' }
      ],
      wsGHeaders: [
        {
          text: 'Global Layer ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Layer Name', value: 'name', sortable: false, width: '60%' }
      ],
      wsCHeaders: [
        {
          text: 'Customer Layer ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Layer Name', value: 'name', sortable: false, width: '60%' }
      ]
    };
  },

  computed: {
    ...mapState(['customer']),
    resetIsDisabled() {
      return this.customer.defaultService[this.scope].default_id === '';
    },
    scope() {
      return this.appScope.replace('-', '');
    },
    fromGlobal() {
      return this.customer.defaultService.GLOBAL.service_type === 'WS' && this.customer.defaultService.GLOBAL.service_path.includes(this.appScope);
    },
    sameAsGlobal() {
      return isEqual(this.customer.defaultService.GLOBAL.service_details, this.customer.defaultService[this.scope].service_details);
    }
  },

  methods: {
    ...mapActions(['storeCustomerCommonServices', 'deleteDefaultServiceAction', 'storeDefaultService', 'createDefaultServiceAction', 'updateDefaultServiceAction']),
    async resetDefaultService() {
      this.overlay = true;
      this.isConfig = false;
      const data = {
        default_id: this.customer.defaultService[this.scope].default_id,
        customer_id: this.customer.crm_id
      };
      await this.deleteDefaultServiceAction(data);
      await this.storeDefaultService({ customer_id: this.customer.crm_id, app_scope: this.appScope });
      this.overlay = false;
    },
    async openConfiguration() {
      this.overlay = true;
      await this.storeCustomerCommonServices(this.customer.crm_id);
      this.isConfig = false;
      this.$nextTick(() => {
        this.isConfig = true;
      });
      this.overlay = false;
    },
    async copyConfiguration() {
      this.overlay = true;
      const data = {
        customer_id: this.customer.crm_id,
        service_type: 'WS',
        ws_group: this.appScope,
        service_details: this.customer.defaultService.GLOBAL.service_details.map(({ service_id, layer_name, is_meteogram }) => ({ service_id, layer_name, is_meteogram })),
        global_layer_id: [],
        customer_layer_id: []
      };

      if (this.customer.defaultService[this.scope].default_id !== '') {
        data.default_id = this.customer.defaultService[this.scope].default_id;
        await this.updateDefaultServiceAction(data);
      } else {
        data.app_scope = this.appScope;
        await this.createDefaultServiceAction(data);
      }

      await this.storeDefaultService({ customer_id: this.customer.crm_id, app_scope: this.appScope });
      this.overlay = false;
    },
    closeConfiguration() {
      this.isConfig = false;
    }
  }
};
</script>
